import { ChatRoutes, Country, Locale } from '../constants';
import { BusinessArea, LineOfBusiness, TenantMapping } from './types';

import { mdiMedicalBag, mdiBottleTonicPlus } from '@mdi/js';
import profAthleteEnHomeIcon from '../assets/images/homepage/prof-athlete-en.png';
import profAthleteFiHomeIcon from '../assets/images/homepage/prof-athlete.png';
import { BusinessChannel } from '../store/bot-config/types';
import { SupportLinkUrl } from '@/utils/getDefaultSupportLink';

export const AccessibilityStatementUrls = {
  IfFinland: {
    English: 'https://www.if.fi/en/accessibility',
    Finnish: 'https://www.if.fi/saavutettavuus',
    Swedish: 'https://www.if.fi/sv/tillganglighet',
  },
};

const finlandMapping: TenantMapping[] = [
  {
    path: ChatRoutes.FinlandClaimsCenter,
    pageConfig: {
      tenantId: '9c9be6ba-8b09-447b-b10d-6767ef7966fa',
      pageTitleTranslationKey: 'fiClaimsCenterPageTitle',
      country: Country.FI,
      locale: Locale.FI,
    },
  },
  {
    path: ChatRoutes.FinlandPropertyOpenPages,
    pageConfig: {
      tenantId: 'dc673b1e-28fc-44cb-adfa-d7786744ed7d',
      channel: BusinessChannel.OpenPages,
      country: Country.FI,
      locale: Locale.FI,
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
        sv: AccessibilityStatementUrls.IfFinland.Swedish,
        fi: AccessibilityStatementUrls.IfFinland.Finnish,
      },
      contactSupportLinks: {
        default: SupportLinkUrl.FI,
        sv: SupportLinkUrl.SE_FI,
        fi: SupportLinkUrl.FI,
      },
    },
  },
  {
    path: ChatRoutes.FinlandPropertyMyPages,
    pageConfig: {
      tenantId: 'dc673b1e-28fc-44cb-adfa-d7786744ed7d',
      channel: BusinessChannel.MyPages,
      country: Country.FI,
      locale: Locale.FI,
    },
  },
  {
    path: ChatRoutes.FinlandHealthNavigator,
    pageConfig: {
      tenantId: '5a78e530-2956-44d8-a087-4aa8c48f08b7',
      country: Country.FI,
      businessArea: 'Private',
      lineOfBusiness: 'HealthNavigator',
      locale: Locale.FI,
      pageTitleTranslationKey: 'fiHealthNavigatorPageTitle',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
    },
  },
  {
    path: ChatRoutes.FinlandCommercialPersonalInjury,
    pageConfig: {
      tenantId: '10ae44dc-f137-4ca2-950b-cbec0b911fd7',
      homePageSvgIcon: mdiMedicalBag,
      brandName: 'Finland Commercial Personal Injury',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
      country: Country.FI,
      locale: Locale.FI,
    },
  },
  {
    path: ChatRoutes.FinlandProfesionalAthletesEng,
    pageConfig: {
      tenantId: '3b8ac8d6-2c13-4c39-b893-ea991b162185',
      pageTitleTranslationKey: 'fiProfAthletesEngPageTitle',
      homePageIcon: profAthleteEnHomeIcon,
      brandName: 'Professional Athletes EN',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.English,
      },
      country: Country.FI,
      locale: Locale.EN,
    },
  },
  {
    path: ChatRoutes.FinlandPrivatePersonalInjury,
    pageConfig: {
      tenantId: 'c264cf0c-7e28-48d6-9f1c-3c7962edc2bd',
      country: Country.FI,
      locale: Locale.FI,
      lineOfBusiness: 'PersonalInjury',
      businessArea: 'Private',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
    },
  },
  {
    path: ChatRoutes.FinlandCommercialPersonalInjuryEB,
    pageConfig: {
      tenantId: 'c6a28df0-8eeb-4a8d-97ae-fb400fbb412a',
      locale: Locale.FI,
      country: Country.FI,
      brandName: 'EB Health',
      homePageSvgIcon: mdiBottleTonicPlus,
      pageTitleTranslationKey: 'fiCommercialPersonalInjuryEbHealthPageTitle',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
        sv: AccessibilityStatementUrls.IfFinland.Swedish,
        fi: AccessibilityStatementUrls.IfFinland.Finnish,
      },
      contactSupportLinks: {
        default: SupportLinkUrl.FI,
        sv: SupportLinkUrl.SE_FI,
        fi: SupportLinkUrl.FI,
      },
    },
  },
  {
    path: ChatRoutes.FinlandPrivatePersonalInjurySE,
    pageConfig: {
      tenantId: 'c264cf0c-7e28-48d6-9f1c-3c7962edc2bd',
      country: Country.FI,
      locale: Locale.SV,
      lineOfBusiness: 'PersonalInjury',
      businessArea: 'Private',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
    },
  },
  {
    path: ChatRoutes.FinlandMotor,
    pageConfig: {
      tenantId: '6b941d97-f5ec-4ba6-a372-327792e6c268',
      country: Country.FI,
      businessArea: 'Private',
      locale: Locale.FI,
      pageTitleTranslationKey: 'fiMotorPageTitle',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
    },
  },
  {
    path: ChatRoutes.FinlandMotorRentalCar,
    pageConfig: {
      tenantId: '6b941d97-f5ec-4ba6-a372-327792e6c268',
      country: Country.FI,
      businessArea: 'Private',
      locale: Locale.FI,
      pageTitleTranslationKey: 'fiMotorPageTitle',
      claimType: 'sijaisauto',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
    },
  },
  {
    path: ChatRoutes.FinlandMotorTowing,
    pageConfig: {
      tenantId: '6b941d97-f5ec-4ba6-a372-327792e6c268',
      country: Country.FI,
      businessArea: 'Private',
      locale: Locale.FI,
      pageTitleTranslationKey: 'fiMotorPageTitle',
      claimType: 'hinaus',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
    },
  },
  {
    path: ChatRoutes.FinlandMotorGlass,
    pageConfig: {
      tenantId: '6b941d97-f5ec-4ba6-a372-327792e6c268',
      country: Country.FI,
      businessArea: 'Private',
      locale: Locale.FI,
      pageTitleTranslationKey: 'fiMotorPageTitle',
      claimType: 'lasi',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
    },
  },
  {
    path: ChatRoutes.FinlandTravelOpenPages,
    pageConfig: {
      tenantId: 'b3248d58-284b-43c0-947d-22c7419efad8',
      channel: BusinessChannel.OpenPages,
      country: Country.FI,
      businessArea: 'Private',
      locale: Locale.FI,
      pageTitleTranslationKey: 'fiTravelPageTitle',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
        sv: AccessibilityStatementUrls.IfFinland.Swedish,
        fi: AccessibilityStatementUrls.IfFinland.Finnish,
      },
      contactSupportLinks: {
        default: SupportLinkUrl.FI,
        sv: SupportLinkUrl.SE_FI,
        fi: SupportLinkUrl.FI,
      },
    },
  },
  {
    path: ChatRoutes.FinlandTravelMyPages,
    pageConfig: {
      tenantId: 'b3248d58-284b-43c0-947d-22c7419efad8',
      channel: BusinessChannel.MyPages,
      country: Country.FI,
      businessArea: 'Private',
      locale: Locale.FI,
      pageTitleTranslationKey: 'fiTravelPageTitle',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
    },
  },

  {
    path: ChatRoutes.FinlandBoat,
    pageConfig: {
      tenantId: '67b00004-40b8-4e27-b86b-d8dfb25f0a1c',
      country: Country.FI,
      businessArea: 'Private',
      locale: Locale.FI,
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
    },
  },
  {
    path: ChatRoutes.FinlandProfesionalAthletes,
    pageConfig: {
      tenantId: 'd2298780-a222-4f7d-8a94-93ebba7afdde',
      pageTitleTranslationKey: 'fiProfAthletesPageTitle',
      brandName: 'Professional Athletes FI',
      homePageIcon: profAthleteFiHomeIcon,
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
      country: Country.FI,
      locale: Locale.FI,
    },
  },
  {
    path: ChatRoutes.FinlandForest,
    pageConfig: {
      tenantId: 'c5819476-c8f3-4a9c-a7ac-54e2a96731a5',
      pageTitleTranslationKey: 'fiForestPageTitle',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
        sv: AccessibilityStatementUrls.IfFinland.Swedish,
        fi: AccessibilityStatementUrls.IfFinland.Finnish,
      },
      contactSupportLinks: {
        default: SupportLinkUrl.FI,
        sv: SupportLinkUrl.SE_FI,
        fi: SupportLinkUrl.FI,
      },
      country: Country.FI,
      locale: Locale.FI,
    },
  },
  {
    path: ChatRoutes.LegalAndLiability,
    pageConfig: {
      tenantId: 'b0d64c41-b4b0-4307-bfdc-4132f8bfb5ca',
      pageTitleTranslationKey: 'fiLegalAndLiabilityPageTitle',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
      country: Country.FI,
      locale: Locale.FI,
    },
  },
  {
    path: ChatRoutes.FinlandAnimal,
    pageConfig: {
      tenantId: '2a4b00c2-d560-4ae6-9665-180c0c2e4453',
      pageTitleTranslationKey: 'fiAnimalPageTitle',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
        sv: AccessibilityStatementUrls.IfFinland.Swedish,
        fi: AccessibilityStatementUrls.IfFinland.Finnish,
      },
      contactSupportLinks: {
        default: SupportLinkUrl.FI,
        sv: SupportLinkUrl.SE_FI,
        fi: SupportLinkUrl.FI,
      },
      country: Country.FI,
      locale: Locale.FI,
    },
  },
  {
    path: ChatRoutes.FinlandCargoAndLiability,
    pageConfig: {
      tenantId: '6ec0f4dc-3d20-4c55-9755-6678c10b29c0',
      pageTitleTranslationKey: 'pageTitle',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
      country: Country.FI,
      locale: Locale.FI,
    },
  },
  {
    path: ChatRoutes.FinlandBusinessClaimsProperty,
    pageConfig: {
      tenantId: '6ec0f4dc-3d20-4c55-9755-6678c10b29c0',
      pageTitleTranslationKey: 'pageTitle',
      accessibilityStatementUrls: {
        default: AccessibilityStatementUrls.IfFinland.Finnish,
      },
      country: Country.FI,
      locale: Locale.FI,
      businessArea: BusinessArea.COMMERCIAL,
      lineOfBusiness: LineOfBusiness.PROPERTY,
    },
  },
];

export default finlandMapping;
